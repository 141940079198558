<template>
  <div class="detailBox">
    <el-form
      ref="detailBoxidCom"
      :model="form"
      :rules="rules"
      label-width="110px"
      inline
    >
      <el-form-item label="方案名称" prop="groupName">
        <el-input
          placeholder="方案名称"
          size="small"
          v-model="form.groupName"
          class="w_style"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <span>匹配模式</span>

          <el-popover trigger="hover" placement="bottom">
            <div>
              <p>
                短语匹配：必须出现所输入词语，例如：中国，必须出现‘中国’这个词的消息才会命中。
              </p>
              <p>
                分词匹配：所输入词语可能会命中1个字也会匹配出来，例如：中国，出现‘中’或者‘国’的任一词的消息都会匹配出来。
              </p>
              <p>
                分词匹配(全包含)：同分词匹配，但区别在于，不管词语被分成了几个字，这些字必须全部包含才算命中，例如：中国，出现‘中’且必须出现‘国’的消息才会匹配出来。
              </p>
            </div>
            <i
              slot="reference"
              class="el-icon-question"
              style="margin-left: 0"
            ></i>
          </el-popover>
        </template>
        <el-select v-model="form.keyType" size="small" class="w_style">
          <el-option :value="0" label="短语匹配" />
          <el-option :value="1" label="分词匹配" />
          <el-option :value="2" label="分词匹配(必须全包含)" />
        </el-select>
      </el-form-item>
      <el-form-item prop="skey">
        <template slot="label">
          <span>检索词规则</span>

          <el-popover trigger="hover" placement="bottom">
            <div>
              <p>检索词配置规则：</p>
              <p>
                检索词可配置多组，组之间用空格间隔，组之间关系为或的关系；每组可填写一个词语或者多个词语，多个词语请用&间隔，组内词语间关系为与的关系。
              </p>
              <p>
                例如配置规则1：中国 中国&天安门
                中国&故宫，该规则会匹配出（包含中国的词语或者包含中国且必须包含天安门的词语或者包含中国且必须包含故宫的词语）
              </p>
              <p>
                例如配置规则2：中国 天安门
                故宫，该规则会匹配出（包含中国的词语或者包含天安门的词语或者包含故宫的词语）
              </p>
            </div>
            <i
              slot="reference"
              class="el-icon-question"
              style="margin-left: 0"
            ></i>
          </el-popover>
        </template>
        <el-input
          size="small"
          class="w_style"
          placeholder="请输入检索词规则"
          type="textarea"
          v-model="form.skey"
        ></el-input>
      </el-form-item>

      <el-form-item label="排除词">
        <el-input
          size="small"
          class="w_style"
          v-model="form.excludeKey"
          placeholder="请输入排除词"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="检索群ID">
        <template slot="label">
          <span>检索群ID</span>

          <el-popover trigger="hover" placement="bottom">
            <div>
              <p>
                所输入群ID，必须存在于系统内，提交时系统会自动过滤不存在的群ID。
              </p>
            </div>
            <i
              slot="reference"
              class="el-icon-question"
              style="margin-left: 0"
            ></i>
          </el-popover>
        </template>
        <el-input
          size="small"
          class="w_style"
          v-model="form.fromChannelId"
          placeholder="请输入群ID"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="检索账号ID">
        <template slot="label">
          <span>检索账号ID</span>

          <el-popover trigger="hover" placement="bottom">
            <div>
              <p>
                所输入账号ID，必须存在于系统内，提交时系统会自动过滤不存在的账号ID。
              </p>
            </div>
            <i
              slot="reference"
              class="el-icon-question"
              style="margin-left: 0"
            ></i>
          </el-popover>
        </template>
        <el-input
          size="small"
          class="w_style"
          v-model="form.fromUserId"
          placeholder="请输入检索账号ID"
          type="textarea"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否包含图片">
        <el-select
          size="small"
          class="w_style"
          v-model="form.isContainImage"
          placeholder="请选择是否包含图片"
        >
          <el-option :value="null" label="全部" />
          <el-option :value="0" label="不包含图片" />
          <el-option :value="1" label="仅图片" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否包含视频">
        <el-select
          size="small"
          class="w_style"
          v-model="form.isContainVideo"
          placeholder="请选择是否包含视频"
        >
          <el-option :value="null" label="全部" />
          <el-option :value="0" label="不包含视频" />
          <el-option :value="1" label="仅视频" />
        </el-select>
      </el-form-item>

      <el-form-item label="是否检索转发">
        <el-select
          size="small"
          class="w_style"
          v-model="form.isForward"
          placeholder="请选择是否检索转发"
        >
          <el-option :value="null" label="全部" />
          <el-option :value="1" label="仅转发" />
          <el-option :value="0" label="不包含转发" />
        </el-select>
      </el-form-item>
      <el-form-item label="检索开始时间">
        <el-date-picker
          type="datetime"
          size="small"
          placeholder="开始日期"
          v-model="form.startDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          class="w_style"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="检索截止时间">
        <el-date-picker
          type="datetime"
          placeholder="检索截止时间"
          v-model="form.endDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          class="w_style"
        ></el-date-picker>
      </el-form-item>

      <div style="text-align:center">
        <el-button
          type="primary"
          @click="saveOrUpdate('detailBoxidCom')"
          size="small"
        >
          提交修改
        </el-button>
      </div>
      <!-- <el-button>取 消</el-button> -->
    </el-form>
  </div>
</template>

<script>
import { detailKeyword, update } from '@/api/monitor'
export default {
  name: 'Detail',
  // import引入的组件需要注入到对象中才能使用",
  props: {
    infoId: {
      type: Number
    }
  },
  data() {
    return {
      form: {},
      rules: {
        groupName: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        skey: [{ required: true, message: '请输入检索词规则', trigger: 'blur' }]
      }
    }
  },
  created() {},
  methods: {
    newList() {
      return new Promise((resolve, reject) => {
        detailKeyword(this.infoId).then(data => {
          this.form = Object.assign({}, data)
          if (!data.isContainImage) {
            this.$set(this.form, 'isContainImage', null)
          }
          if (!data.isContainVideo) {
            this.$set(this.form, 'isContainVideo', null)
          }
          if (!data.isForward) {
            this.$set(this.form, 'isForward', null)
          }
          console.log(this.form)
          resolve()
        })
      })
    },
    resetForm() {
      this.$refs['detailBoxidCom'].resetFields()
    },
    saveOrUpdate(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.infoId) {
            // 修改
            update(this.infoId, this.form)
              .then(res => {
                console.log(this.form)
                // 提示信息
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                })
                //this.resetData()
                this.$emit('editDone')
              })
              .catch(e => {
                this.loading = false
              })
          }
        } else {
          this.loading = false
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.detailBox {
  overflow: hidden;
  ::v-deep .el-form-item {
    width: calc(50% - 10px);
    .el-form-item__content {
      width: calc(100% - 110px);
    }
  }
  .w_style {
    width: 100%;
  }
}
</style>
