<template>
  <div class="comment-index">
    <div class="comment-index-left">
      <div>
        <el-form :model="submitForm" ref="submitForm">
          <el-form-item
            prop="groupName"
            label="方案名称"
            :rules="{
              required: true,
              message: '方案名称不能为空'
            }"
          >
            <el-input
              v-model="submitForm.groupName"
              clearable
              size="small"
              placeholder="请输入方案名称"
              class="w_100"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="skey"
            :rules="{
              required: true,
              message: '检索词规则不能为空'
            }"
          >
            <template slot="label">
              <span>检索词规则</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>检索词配置规则：</p>
                  <p>
                    检索词可配置多组，组之间用空格间隔，组之间关系为或的关系；每组可填写一个词语或者多个词语，多个词语请用&间隔，组内词语间关系为与的关系。
                  </p>
                  <p>
                    例如配置规则1：中国 中国&天安门
                    中国&故宫，该规则会匹配出（包含中国的词语或者包含中国且必须包含天安门的词语或者包含中国且必须包含故宫的词语）
                  </p>
                  <p>
                    例如配置规则2：中国 天安门
                    故宫，该规则会匹配出（包含中国的词语或者包含天安门的词语或者包含故宫的词语）
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              size="small"
              class="w_100"
              placeholder="请输入检索词规则"
              type="textarea"
              v-model="submitForm.skey"
            ></el-input>

            <!-- -->
          </el-form-item>

          <el-form-item label="排除词">
            <el-input
              v-model="submitForm.excludeKey"
              clearable
              size="small"
              placeholder="请输入排除词,多个以空格间隔"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span>检索群 ID</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    所输入群ID，必须存在于系统内，提交时系统会自动过滤不存在的群ID。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              v-model="submitForm.fromChannelId"
              clearable
              size="small"
              type="textarea"
              placeholder="请输入群ID，多个以空格间隔"
              class="w_100"
            ></el-input>
          </el-form-item>
          <el-form-item label="检索账号ID">
            <template slot="label">
              <span>检索账号ID</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    所输入账号ID，必须存在于系统内，提交时系统会自动过滤不存在的账号ID。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              v-model="submitForm.fromUserId"
              clearable
              size="small"
              type="textarea"
              placeholder="来自账号，多个以空格间隔"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <span>检索词匹配模式</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    短语匹配：必须出现所输入词语，例如：中国，必须出现‘中国’这个词的消息才会命中。
                  </p>
                  <p>
                    分词匹配：所输入词语可能会命中1个字也会匹配出来，例如：中国，出现‘中’或者‘国’的任一词的消息都会匹配出来。
                  </p>
                  <p>
                    分词匹配(全包含)：同分词匹配，但区别在于，不管词语被分成了几个字，这些字必须全部包含才算命中，例如：中国，出现‘中’且必须出现‘国’的消息才会匹配出来。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-select
              size="small"
              v-model="submitForm.keyType"
              placeholder="请选择匹配模式"
              class="w_100"
            >
              <el-option :value="0" label="短语匹配" />
              <el-option :value="1" label="分词匹配" />
              <el-option :value="2" label="分词匹配(必须全包含)" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否包含图片">
            <el-select
              size="small"
              v-model="submitForm.isContainImage"
              placeholder="是否包含图片"
              class="w_100"
            >
              <el-option :value="null" label="全部" />
              <el-option :value="0" label="不包含图片" />
              <el-option :value="1" label="仅图片" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否包含视频">
            <el-select
              size="small"
              v-model="submitForm.isContainVideo"
              placeholder="请选择是否包含视频"
              style="width: 100%"
            >
              <el-option :value="null" label="全部" />
              <el-option :value="0" label="不包含视频" />
              <el-option :value="1" label="仅视频" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否检索转发">
            <el-select
              size="small"
              v-model="submitForm.isForward"
              placeholder="请选择是否检索转发"
              class="w_100"
            >
              <el-option :value="null" label="全部" />
              <el-option :value="1" label="仅转发" />
              <el-option label="不包含转发" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="检索开始日期">
            <el-date-picker
              type="datetime"
              size="small"
              placeholder="开始日期"
              v-model="submitForm.startDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="检索截止日期">
            <el-date-picker
              type="datetime"
              size="small"
              placeholder="截止日期"
              v-model="submitForm.endDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <el-button
        type="primary"
        size="mini"
        style="width: 100%; margin-top: 10px; border-radius: 16px"
        @click="addMoniter('submitForm')"
        :loading="loading"
      >
        添加方案
      </el-button>
      <el-button
        size="mini"
        style="width: 100%; margin-top: 10px; border-radius: 16px;margin-left: 0px;"
        @click="resetForm('submitForm')"
      >
        重置
      </el-button>
    </div>
    <div class="comment-index-right">
      <el-table
        :data="accountList"
        header-row-class-name="tableheader"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        class="table-list"
        v-loading="loading"
      >
        <el-table-column prop="groupName" label="方案名称"></el-table-column>
        <el-table-column prop="skey" label="包含关键词"></el-table-column>
        <el-table-column prop="keyType" label="匹配模式">
          <template slot-scope="scope">
            {{ getTextStatus(scope.row.keyType) }}
          </template>
        </el-table-column>
        <el-table-column prop="excludeKey" label="排除词"></el-table-column>
        <el-table-column prop="fromChannelId" label="来自群">
          <template slot-scope="scope" v-if="scope.row.fromChannelList">
            <div
              style="height:25px"
              v-for="(item, i) in scope.row.fromChannelList"
              :key="i"
            >
              <el-popover placement="right" width="300" trigger="hover">
                <div>
                  <el-descriptions
                    :column="1"
                    :labelStyle="{
                      width: '60px',
                      textAlign: 'right',
                      color: '#060606'
                    }"
                  >
                    <el-descriptions-item label="群头像">
                      <img
                        style="width:25px;height:25px"
                        :src="
                          item.channelPhoto
                            ? 'data:image/png;base64,' + item.channelPhoto
                            : require('@/assets/imgs/p.png')
                        "
                        alt=""
                      />
                    </el-descriptions-item>
                    <el-descriptions-item label="群名">
                      {{ item.channelTitle }}
                    </el-descriptions-item>
                    <el-descriptions-item label="群号">
                      {{ item.channelId }}
                    </el-descriptions-item>

                    <el-descriptions-item label="群用户名">
                      {{ item.channelUsername }}
                    </el-descriptions-item>

                    <el-descriptions-item label="群简介">
                      {{ item.channelSummary }}
                    </el-descriptions-item>
                    <el-descriptions-item label="群检索">
                      <span class="retrieval-span" @click="retrieval(item)">
                        检索
                      </span>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <span slot="reference" class="channelId-span">
                  {{ item.channelId }}
                </span>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fromUserList" label="来自账号">
          <template slot-scope="scope" v-if="scope.row.fromUserList">
            <div
              style="height:25px"
              v-for="(item, i) in scope.row.fromUserList"
              :key="i"
            >
              <el-popover placement="right" width="300" trigger="hover">
                <div>
                  <el-descriptions
                    :column="1"
                    :labelStyle="{
                      width: '60px',
                      textAlign: 'right',
                      color: '#060606'
                    }"
                  >
                    <el-descriptions-item label="用户名">
                      {{ item.userUsername }}
                    </el-descriptions-item>
                    <el-descriptions-item label="姓">
                      {{ item.userLastName }}
                    </el-descriptions-item>

                    <el-descriptions-item label="名">
                      {{ item.userFirstName }}
                    </el-descriptions-item>

                    <el-descriptions-item label="用户id">
                      {{ item.userId }}
                    </el-descriptions-item>
                    <el-descriptions-item label="用户检索">
                      <span
                        class="retrieval-span"
                        @click="userRetrieval(item.userId)"
                      >
                        检索
                      </span>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <span slot="reference" class="channelId-span">
                  {{ item.userId }}
                </span>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <!-- <el-table-column
          prop="updateTime"
          label="检索结束时间"
        ></el-table-column> -->
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="exportFun(scope.row.id)">
              方案消息导出
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="listDetail(scope.row.id)"
            >
              详情
            </el-button>
            <el-button
              type="text"
              size="mini"
              style="color:red"
              @click="removeById(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!---详情编辑弹窗--->
      <el-dialog title="详情" :visible.sync="dialogVisible" width="50%">
        <detail
          :infoId="detailBoxid"
          ref="detailBoxidCom"
          v-on:editDone="editDone"
        />
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">
            确 定
          </el-button>
        </span> -->
      </el-dialog>
      <!--分页组件开始-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="[30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="text-align: center; margin-top: 10px"
      ></el-pagination>
      <!--分页组件结束-->
    </div>
  </div>
</template>

<script>
import { addKeywordMonitorTask, list, delKeyword } from '@/api/monitor'
import detail from '@/views/monitor/detail'
export default {
  name: 'MonitorKeyword',
  components: { detail },
  data() {
    return {
      dialogVisible: false,
      submitForm: {
        groupName: '',
        skey: '',
        keyType: 0,
        excludeKey: '',
        fromChannelId: '',
        fromUserId: '',
        isContainImage: null,
        isContainVideo: null,
        isForward: null,
        startDate: '',
        endDate: ''
      },
      loading: false,
      accountList: [],
      pageIndex: 1,
      pageSize: 30,
      total: 0,
      detailBoxid: ''
    }
  },

  created() {
    this.getList()
  },
  methods: {
    exportFun(id) {
      let href = process.env.VUE_APP_BASE_API + `/userKey/export/${id}`
      window.open(href, '_blank')
    },
    // 群检索
    retrieval(row) {
      this.$router.push('/group/detail/' + row.channelId)
    },
    // 用户检索
    userRetrieval(id) {
      this.$router.push('/user/detail/' + id)
    },
    // 添加方案
    addMoniter(submitForm) {
      this.$refs[submitForm].validate(valid => {
        if (valid) {
          addKeywordMonitorTask(this.submitForm).then(res => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })

            this.$nextTick(() => {
              this.submitForm.groupName = ''
              this.submitForm.skey = ''
              this.submitForm.keyType = 0
              this.submitForm.excludeKey = ''
              this.submitForm.fromChannelId = ''
              this.submitForm.fromUserId = ''
              this.submitForm.isContainImage = null
              this.submitForm.isContainVideo = null
              this.submitForm.isForward = null
              this.submitForm.startDate = ''
              this.submitForm.endDate = ''
              this.resetForm('submitForm')
            })
            // 刷新列表页面
            this.getList()
          })
        } else {
          return false
        }
      })
    },
    // 重置
    resetForm(submitForm) {
      this.submitForm.groupName = ''
      this.submitForm.skey = ''
      this.submitForm.keyType = 0
      this.submitForm.excludeKey = ''
      this.submitForm.fromChannelId = ''
      this.submitForm.fromUserId = ''
      this.submitForm.isContainImage = null
      this.submitForm.isContainVideo = null
      this.submitForm.isForward = null
      this.submitForm.startDate = ''
      this.submitForm.endDate = ''

      this.$nextTick(() => {
        this.$refs.submitForm.resetFields()
      })
    },
    // 获取列表
    getList() {
      this.loading = true
      list(this.pageIndex, this.pageSize)
        .then(res => {
          this.accountList = res.items
          this.total = res.all_count
          this.loading = false
        })
        .catch(err => {
          // 请求失败
          console.log(err)
          this.loading = false
        })
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.pageIndex = 1
      this.getList()
    },
    handleCurrentChange(val) {
      // 当前页码发生变化时
      this.pageIndex = val
      this.getList()
    },
    // 删除
    removeById(id) {
      this.$confirm('此操作将删除该条方案, 是否继续?', '提示', {
        // 设置 cancel 和 close 为不同的事件
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancalButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delKeyword(id)
          .then(response => {
            // 提示信息
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            // 刷新列表页面
            this.getList()
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    // 详情
    listDetail(id) {
      this.detailBoxid = id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.detailBoxidCom.resetForm()
        this.$refs.detailBoxidCom.newList()
      })
    },
    //修改后
    editDone() {
      this.getList()
      this.dialogVisible = false
    },
    getTextStatus(status) {
      switch (status) {
        case 0:
          return '短语匹配'
        case 1:
          return '分词匹配 '
        case 2:
          return '分词匹配(必须全包含)'
      }
    }
  }
}
</script>

<style lang="scss">
.w_100 {
  width: 100%;
}
.comment-index {
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .comment-index-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    padding-right: 0;
    overflow: hidden;
    .el-form-item__error {
      // top: 92%;
    }
    > div {
      height: calc(100% - 80px);
      padding-right: 10px;
      overflow: auto;
    }
    .left-list {
      height: calc(100% - 385px);
      overflow: hidden;
      overflow-y: auto;

      margin-top: 20px;
      .left-list-title {
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
        border-left: 4px solid #0079d3;
        padding-left: 10px;
      }
      .left-list-title-b {
        margin: 10px 0;
        border-bottom: 1px solid #fbf6f6;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 15px;
          .history-words {
            padding: 5px 10px;
            background: #efefef;
            border: 0px solid #000000;
            border-radius: 20px;

            font-size: 12px;
            font-weight: 300;
            color: #2a2a2a;
            cursor: pointer;
            user-select: none;
          }
          .select-obj {
            background: #ff4500;
            color: #ffffff;
          }
        }
      }
    }
    .el-form-item {
      margin-bottom: 10px !important;
    }
    // .el-form-item__error {
    // position: absolute;
    // left: 0;
    // bottom: 10px;
    // }
  }
  .comment-index-right {
    width: calc(100% - 270px);
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 5px 0 rgb(82 63 105 / 20%);
    border: 1px solid #ebeef5;
    border-radius: 10px;
  }
  .table-list {
    background: #fff;
    height: calc(100% - 40px);
    .el-table__body-wrapper {
      height: calc(100% - 40px);
      overflow: auto;
    }
    td {
      height: 50px !important;
      line-height: 45px;
    }
  }
  .table-list .has-gutter th {
    background: #f0f3f9;
    border-top: 1px solid #e4e6ea;
  }
  .channelId-span {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #0079d3;
    }
  }
}
.retrieval-span {
  text-decoration: underline;
  color: #0079d3;
  cursor: pointer;
  user-select: none;
}
.comment-index .el-icon-question {
  position: relative;
  color: #7e7a7a;
  margin-left: 5px;
  cursor: pointer;
  z-index: 999;
}
</style>
